import { FormikAutocomplete, MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"

export function Fields(
  values,
  optionsWafers,
  productGroup,
  setFieldValue,
  state,
  products
) {
  const YN = [
    {
      id: 0,
      optionLabel: "NO",
    },
    {
      id: 1,
      optionLabel: "SI",
    },
  ]

  return [
    {
      id: 1,
      class: "textfield",
      name: "producto",
      label: "Nombre",
      value: values.producto,
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={optionsWafers.find((v) => v.id === values.conf_oblea)}
          label="Pieza de oblea"
          name="conf_oblea"
          options={optionsWafers}
          getOptionLabel={(e) => e.oblea_rudac}
          onChange={(_, value, reason) => {
            setFieldValue("conf_oblea", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.taller)}
          label="Preparación: taller"
          name="taller"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("taller", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.colocado)}
          label="Stock colocado"
          name="colocado"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("colocado", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.foto)}
          label="Foto obligatoria"
          name="foto"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("foto", value.id)
          }}
          disabled={!values.colocado}
        />
      ),
    },
    {
      class: "textfield",
      sm: 6,
      xs: 12,
      name: "orden_colocado",
      label: "Orden colocados",
      value: values.orden_colocado,
      type: "number",
      disabled: !values.colocado,
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={productGroup.find(
            (v) => v.value === values.grupo_colocado
          )}
          label="Grupo colocado"
          name="grupo_colocado"
          options={productGroup}
          getOptionLabel={(e) => e.value}
          onChange={(_, value) => {
            setFieldValue("grupo_colocado", value.value)
          }}
          disabled={!values.colocado}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.activo)}
          label="Activo"
          name="activo"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("activo", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.es_registral)}
          label="Es registral?"
          name="es_registral"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("es_registral", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 6,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={YN.find((v) => v.id === values.es_chasis)}
          label="Es chasis?"
          name="es_chasis"
          options={YN}
          getOptionLabel={(e) => e.optionLabel}
          onChange={(_, value) => {
            setFieldValue("es_chasis", value.id)
          }}
        />
      ),
    },
    {
      class: "textfield",
      sm: 12,
      xs: 12,
      name: "limite",
      label: "Límite",
      value: values.limite,
      type: "number",
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          name="pieza_madre"
          options={products}
          getOptionLabel={(e) => e.producto}
          value={products.filter((p) => 
            values?.pieza_madre?.length > 0 
                ? values.pieza_madre.includes(p.id) 
                : values.subpiezas_asociadas?.some(sp => sp.pieza_madre_id === p.id)
          )}        
          label={"Pertenece a conjunto"}
          onChange={(_, value) => {
            setFieldValue("pieza_madre", value.map((v) => v.id))
            setFieldValue("subpiezas_asociadas", value.map((v) => v.id))
          }}
          tagName={"producto"}
          heightInput="auto"
        />
      ),
      sm: 12,
      xs: 12,
    },    
    {
      id: 6,
      class: state.checked ? "textfield" : null,
      label: "Excelente",
      name: "calidad[0].excelente_detalle",
      value: values.calidad[0].excelente_detalle,
      sm: 12,
      xs: 12,
    },
    {
      id: 7,
      class: state.checked ? "textfield" : null,
      label: "Bueno",
      name: "calidad[0].bueno_detalle",
      value: values.calidad[0].bueno_detalle,
      sm: 12,
      xs: 12,
    },
    {
      id: 8,
      class: state.checked ? "textfield" : null,
      label: "Regular",
      name: "calidad[0].regular_detalle",
      value: values.calidad[0].regular_detalle,
      sm: 12,
      xs: 12,
    },
    {
      id: 9,
      class: state.checked ? "textfield" : null,
      label: "Malo",
      name: "calidad[0].malo_detalle",
      value: values.calidad[0].malo_detalle,
      sm: 12,
      xs: 12,
    },
    {
      id: 10,
      class: state.checked ? "textfield" : null,
      label: "A preparar",
      name: "calidad[0].a_preparar",
      value: values.calidad[0].a_preparar,
      sm: 12,
      xs: 12,
    },
    {
      id: 11,
      class: state.checked ? "textfield" : null,
      label: "Scrap",
      name: "calidad[0].scrap",
      value: values.calidad[0].scrap,
      sm: 12,
      xs: 12,
    },
    {
      id: 12,
      class: state.checked ? "textfield" : null,
      label: "Nuevo",
      name: "calidad[0].nuevo",
      value: values.calidad[0].nuevo,
      sm: 12,
      xs: 12,
    },
  ]
}
