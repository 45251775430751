import React, { useRef, useEffect, useState } from "react"
import { Formik, Form } from "formik"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import InputsWithValidate from "Components/UI/Molecules/InputsWithValidate"
import { MultiAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { UBICACIONES_MANUAL } from "Constants"
import { interfaces } from "service/interfaces"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"

import { FormikAutocomplete } from "Components/UI/Atoms/CustomAutocomplete"
import { Typography } from "@material-ui/core"

const ExportStock = (props) => {
  const { onClose, open } = props
  const createDismissableSnackbar = useCustomSnackbar()
  const [loading, setLoading] = useState(false)

  const ref = useRef(null)

  const handleSubmit = () => {
    ref.current.click()
  }

  const [data, setData] = useState({ productos: [], estados: [], familias: [] })
  const [formikValues, setFormikValues] = useState({
    productos: [],
  })

  useEffect(() => {
    interfaces.salesStock.beforeExport().then((response) => {
      setData({
        productos: response.data.products,
        estados: response.data.states,
        familias: response.data.families,
      })
    })
  }, [])

  const enteprises = [
    { id: 0, label: "Ambas", group: "" },
    { id: 1, label: "ECO", group: "" },
    { id: 2, label: "OEM", group: "" },
    { id: 3, label: "Usados", group: "Otros" },
    { id: 4, label: "Nuevos", group: "Otros" },
    { id: 5, label: "Reacondicionados", group: "Otros" },
    { id: 6, label: "Todos", group: "Todos" },
  ]

  const locations = [
    { id: 0, nombre: "Todas" },
    ...UBICACIONES_MANUAL.filter((e) => e.id < 5),
  ]
  const states = [
    { id: 0, descripcion: "En stock" },
    ...data.estados,
    { id: 7, descripcion: "Todos" },
  ]

  async function onSubmit(values) {
    setLoading(true)
    values.productos = values.productos.map((x) => {
      return x.id
    })
    await interfaces.salesStock
      .exportStock(values)
      .then((res) => {
        window.location.assign(res.data)
        createDismissableSnackbar("Tabla de stock descargada!", {
          variant: "success",
        })
        onClose()
      })
      .catch(() => {
        createDismissableSnackbar("Algo salió mal!", {
          variant: "error",
        })
      })
    setLoading(false)
  }

  function onChangeAutocomplete(value, setFieldValue) {
    const filteredPeople = value.filter((item) => item.id !== 0)

    if (value.find((e) => e.id === 0 && e.producto === "Todos")) {
      setFieldValue("productos", value.shift())
    }

    setFieldValue("productos", filteredPeople)
  }

  const SUBPIEZA = [
    {
      id: 0,
      label: "NO",
    },
    {
      id: 1,
      label: "SI",
    },
  ]
  const fields = (setFieldValue, values) => [
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={enteprises.find((v) => v.id === enteprises[0].id)}
          label="Empresa"
          name="empresa"
          options={enteprises}
          groupBy={(options) => options.group}
          getOptionLabel={(e) => e.label}
          onChange={(_, value) => {
            setFieldValue("empresa", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      component: (
        <FormikAutocomplete
          disableClearable={false}
          label="Familias"
          name="familia_id"
          options={data.familias}
          getOptionLabel={(e) => e.descripcion}
          onChange={(_, value, reason) => {
            if (reason === "clear") {
              setFieldValue("familia_id", 0)
            } else {
              setFieldValue("familia_id", value.id)
            }
          }}
          disabled={values.productos.length !== 0}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <MultiAutocomplete
          name="productos"
          options={data.productos}
          getOptionLabel={(e) => e.producto}
          defaultValue={values.productos}
          label={"Productos"}
          onChange={(_, value) => {
            onChangeAutocomplete(value, setFieldValue)
          }}
          tagName={"producto"}
          heightInput="auto"
          disabled={values.familia_id}
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      component: (
        <FormikAutocomplete
          name="subpiezas"
          options={SUBPIEZA} 
          getOptionLabel={(e) => e.label} 
          defaultValue={SUBPIEZA.find((item) => item.id === 1)}
          label={"Subpiezas"}
          onChange={(_, value) => {
            setFieldValue("subpiezas", value.id)
          }}          
        />
      ),
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          
          defaultValue={{ id: 0, descripcion: "En stock" }}
          label="Estado"
          name="estado_id"
          options={states}
          getOptionLabel={(e) => e.descripcion}
          onChange={(_, value) => {
            setFieldValue("estado_id", value.id)
          }}
        />
      ),
    },
    {
      class: "custom",
      sm: 12,
      xs: 12,
      component: (
        <FormikAutocomplete
          defaultValue={{ id: 0, nombre: "Todos" }}
          label="Ubicación"
          name="ubicacion_id"
          options={locations}
          getOptionLabel={(e) => e.nombre}
          onChange={(_, value) => {
            setFieldValue("ubicacion_id", value.id)
          }}
        />
      ),
    },
  ]

  function validateSubmit() {
    if (formikValues.familia_id === 0 && formikValues.productos.length === 0)
      return true
    return false
  }

  return (
    <DialogComponent
      open={open}
      handleclose={onClose}
      title="Exportar stock"
      disableBackdropClick={true}
      handleclosedisabled={loading}
      disableChildHeight
      disableEscapeKeyDown
      actions={
        <CancelConfirmButtons
          onClickSubmit={handleSubmit}
          onClickCancel={onClose}
          disabledCancel={loading}
          disabledSubmit={loading || validateSubmit()}
          isLoading={loading}
        />
      }
      children={
        <Formik
          initialValues={{
            familia_id: 0,
            empresa: 0,
            estado_id: 0,
            ubicacion_id: 0,
            productos: [],
            subpiezas: [{ id: 1, label: "SI" }],
          }}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              {setFormikValues(values)}
              <InputsWithValidate
                fields={fields(setFieldValue, values)}
                spacing={1}
                setFieldValue={setFieldValue}
              />
              <Typography variant="subtitle1">
                En caso de necesitar exportar la totalidad de stock (ya sea
                unificado o de todos los productos) solicitar a Dpto. de
                Sistemas
              </Typography>
              <button style={{ display: "none" }} ref={ref} type="submit" />
            </Form>
          )}
        </Formik>
      }
    />
  )
}

export default ExportStock
