import React, { useEffect, useState } from "react"

import { RESUME, PaymentForm } from "../InfoFields"
import ContentWithSubTabs from "Components/UI/Molecules/ContentWithSubTabs"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import BasicTable from "Components/UI/Atoms/BasicTable"
import { interfaces } from "service/interfaces"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import SetState from "Components/Hooks/SetState"

export const CostsAndRetrat = (props) => {
  const { editValue, handleChange, edit, companies, id, handleChangeMultiple } = props

  const [tab, setTab] = useState(0)
  const [rows, setRows] = useState([])
  const [payments, setPayments] = useState([])
  const [payValues, setPayValues] = useState({
    totalGastos: 0,
    totalIngresos: 0,
    costoTotal: 0,
  })

  const {
    state: paymentDetail,
    setOpen: setOpenPaymentDetail,
    setClose: setClosePaymentDetail,
  } = SetState()

  const listVerticalTabs = [
    {
      id: 0,
      title: "Resumen",
    },
  ]

  const buildTable = (payments) => {
    const tableRows = []
    let totalGastos = 0
    let totalIngresos = 0
    const percent = parseFloat(`0.${editValue.cia_seguros.porcentaje_compulsa}`)

    if (percent > 0) {
      const company = companies.find((c) => c.id === editValue.cia_seguros_id)

      totalGastos = totalGastos + Number(editValue.cotizacion_restos) * percent

      tableRows.push({
        item: tableRows.length + 1,
        concepto: "Comision compulsa",
        proveedor: company.nombre,
        monto: `$${Number(editValue.cotizacion_restos) * percent}`,
        tipo: "Gasto",
      })
    }

    payments
    .filter((payment) => payment.tipo !== "Pago vehículo") 
    .forEach((payment) => {
      tableRows.push({
        item: tableRows.length + 1,
        concepto: payment.tipo,
        proveedor: payment.proveedor,
        monto: `$${payment.monto_total}`,
        tipo: payment.concepto_tipo,
      })
  
      if (payment.concepto_tipo === "Ingreso") {
        totalIngresos = totalIngresos + Number(payment.monto_total)
      } else {
        totalGastos = totalGastos + Number(payment.monto_total)
      }
    })
  

    const total =
      Number(editValue.cotizacion_restos) +
      Number(totalGastos) -
      Number(editValue.cotizacion_retiro) -
      Number(totalIngresos)

    setPayValues({
      totalGastos: totalGastos,
      totalIngresos: totalIngresos,
      costoTotal: total,
    })

    setRows(tableRows)
  }

  const getPayments = async (id) => {
    await interfaces.vehicleInterface
      .getAllPayments(id)
      .then((res) => {
        setPayments(res.data)
        buildTable(res.data)
      })
      .catch((err) => {
        buildTable([])
      })
  }

  const onClickRow = (data, index) => {
    if (data.concepto === "Comision compulsa") return
    setOpenPaymentDetail(payments[index])
  }

  useEffect(() => {
    id ? getPayments(id) : buildTable([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderTabs = (index) => {
    switch (index) {
      case 0:
        return (
          <div style={{ padding: "4rem" }}>
            <InputsWithoutValidate
              fields={RESUME(
                editValue,
                handleChange,
                edit,
                payValues,
                handleChangeMultiple
              )}
              spacing={2}
            />
            <div style={{ height: "15px" }} />
            {rows.length > 0 && (
              <BasicTable
                col={[
                  {
                    title: "Item",
                    style: { width: "55px", cursor: "pointer" },
                  },
                  {
                    title: "Concepto",
                    style: { width: "300px", cursor: "pointer" },
                  },
                  {
                    title: "Proveedor",
                    style: { width: "100px", cursor: "pointer" },
                  },
                  {
                    title: "Monto",
                    style: { width: "100px", cursor: "pointer" },
                  },
                  {
                    title: "Tipo",
                    style: { width: "100px", cursor: "pointer" },
                  },
                ]}
                size={"small"}
                rows={rows}
                onClickRow={onClickRow}
              />
            )}
          </div>
        )
      default:
        break
    }
  }

  return (
    <>
      <ContentWithSubTabs
        listVerticalTabs={listVerticalTabs}
        value={tab}
        setValue={setTab}
        children={renderTabs(tab)}
      />

      <DialogComponent
        open={paymentDetail.open}
        title={`Pago a proveedor #${paymentDetail.id}`}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        handleclose={setClosePaymentDetail}
        widthLarge={"500px"}
        children={
          <InputsWithoutValidate
            fields={PaymentForm(paymentDetail)}
            spacing={2}
          />
        }
      />
    </>
  )
}
