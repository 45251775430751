import React from "react"

import MuiDatables from "Components/UI/Atoms/Table"
import AutocompleteFilter from "Components/UI/Atoms/Table/Autocomplete"
import { interfaces } from "service/interfaces"

import SetState from "Components/Hooks/SetState"
import Edit from "../Edit"

const TableCompaction = (props) => {
  const { render, info, setRender, apiCall } = props
  const { state, setOpen, setClose } = SetState()

  async function compactionInfo(id) {
    await interfaces.plantCompaction
    .infoOneCompaction(id)
    .then((res) => {
      setOpen({
        data: res.data,
      })
    })
  }

  const RowsCompaction = () =>
    info.rows.map((file) => [
      file.pieza,
      file.nrodev,
      file.dominio,
      file.marca,
      file.modelo,
      file.ubicacion,
      file.predio,
      file.lote,
      file.fila,
      file.estado,
      "",
    ])

  const options = [
    {
      id: 1,
      optionLabel: "ECO Río Cuarto",
    },
    {
      id: 2,
      optionLabel: "ECO Spernanzoni",
    },
    {
      id: 3,
      optionLabel: "ECO Ruta 7",
    }
  ]

  const ESTADO = [
    {
      id: 0,
      optionLabel: "Pendiente",
    },
    {
      id: 1,
      optionLabel: "Compactado",
    },
    {
      id: 2,
      optionLabel: "Todos",
    }
  ]

  let columns = [
    {
      name: "pieza",
      label: "Pieza",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "nrodev",
      label: "Nº V",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dominio",
      label: "Dominio",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "marca",
      label: "Marca",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "modelo",
      label: "Modelo",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "ubicacion",
      label: "Ubicación",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Ubicación"}
                  filterName={"optionLabel"}
                  options={options}
                  fullWidth={true}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "predio",
      label: "Predio",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "lote",
      label: "Lote",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "fila",
      label: "Fila",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: false,
        filterType: "custom",
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            if (Object.keys(info).length > 0) {
              return (
                <AutocompleteFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  label={"Estado"}
                  filterName={"optionLabel"}
                  options={ESTADO}
                  defaultOption={{ optionLabel: "Pendiente", id: 0 }}
                  style={{width: '200px', paddingRight: '50px'}}
                  fullWidth={true}
                />
              )
            }
          },
        },
      },
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
      },
    },
  ]

  return (
    <>
      <MuiDatables
        columns={columns}
        data={RowsCompaction()}
        render={render}
        count={info.header}
        title={`Piezas de compactación: ${info.header}`}
        setRender={setRender}
        textPlaceholder="Buscar Nº V o dominio…"
        apiCall={apiCall}
        onRowClick={(_, index) => compactionInfo(info.rows[index.dataIndex].id)}
      />

      {state.suspense && (
        <Edit
          open={state.open}
          handleclose={setClose}
          data={state.data}
          setRender={setRender}
          render={render}
          info={info}
        />
      )}
    </>
  )
}

export default TableCompaction
