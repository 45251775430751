import React, { useState } from "react"
import InputSearch from "Components/UI/Atoms/InputSearch"
import Papers from "Components/UI/Molecules/Papers/PaperList"
import New from "./New"
import Delete from "./Delete"
import Edit from "./Edit"
import ListWithIcon from "Components/UI/Molecules/Lists/ListWithIcon"
import SetState from "Components/Hooks/SetState"
import Filters from "./Filters"
import { Grid } from "@material-ui/core"

const Lots = (props) => {
  const { data, lotId, changeRender, handleListItemClick, setLotId } = props
  
  const [valueLot, setValueLot] = useState("")
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [filteredData, setFilteredData] = useState(data)
  const [openDialog, setOpenDialog] = useState(false)
 
  const {
    state: newLot,
    setOpen: setNewLot,
    setClose: closeNewLot,
  } = SetState()

  const {
    state: deleteLot,
    setOpen: setDelete,
    setClose: setCloseLot,
  } = SetState()

  const {
    state: openEdit,
    setOpen: setOpenEdit,
    setClose: setCloseEdit,
  } = SetState()

  const onChangeLot = (e) => {
    setValueLot(e.target.value)
  }

  function filtered(data) {
    if (data) {
      return data.filter((e) => {
        if (valueLot) {
          return e.nombre.includes(valueLot.toUpperCase())
        } else {
          return e
        }
      })
    } else {
      return []
    }
  }

  const handleApplyFilter = () => {
    let filtered = data

    if (selectedOption !== null) {
      filtered = filtered.filter(item => item.es_compactacion === selectedOption.id)
    }

    if (selectedLocation !== null) {
      filtered = filtered.filter(item => item.ubicacion_id === selectedLocation.id)
    }

    setFilteredData(filtered)

    setOpenDialog(false)
  }

  function addressInfo() {
    return data.filter((e) => {
      return e.id === lotId
    })[0]
  }

  return (
    <>
      <Grid container spacing={2}> 

        <Grid item xs={10}>
          <InputSearch
            button={false}
            onClick={setNewLot}
            onChange={onChangeLot}
            value={valueLot}
          />
        </Grid>

        <Grid item xs={2}> 
          <Filters
            data={data}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            handleApplyFilter={handleApplyFilter}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        </Grid>

      </Grid>
    
       {newLot.suspense && (
        <New
          open={newLot.open}
          handleClose={closeNewLot}
          changeRender={changeRender}
        />
      )}
      
      {deleteLot.suspense && (
        <Delete
          open={deleteLot.open}
          handleClose={setCloseLot}
          selectedIndex={lotId}
          changeRender={changeRender}
          setLotId={setLotId}
        />
      )}
     
      {openEdit.suspense && (
        <Edit
          open={openEdit.open}
          handleclose={setCloseEdit}
          data={addressInfo}
          lotId={lotId}
          changeRender={changeRender}
        />
      )}

      <Papers
        children={
          <ListWithIcon
            data={selectedOption !== null || selectedLocation !== null ? filtered(filteredData) : filtered(data)}
            selectedItem={lotId}
            onClickSelect={handleListItemClick}
            onClickDelete={setDelete}
            onClickEdit={setOpenEdit}
            multiNames={`'Lote ' + item.numero + ' ( ' + (item.nombre ? item.nombre + ' / ': '' )  + (item.ubicacion ? item.ubicacion + ' / ' : '' ) + 'Predio ' + (item.predio ? item.predio : '' ) + ' ) '`}   
          />
        }
        height="500px"
        disabledOn={lotId ? false : true}
      />
    </>
  )
}
export default Lots
