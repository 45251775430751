import React, { useRef, useState } from "react"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import Form from "./Form"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const New = (props) => {
  const { handleClose, open, changeRender } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }

  const [loading, setLoading] = useState(false)

  const onSubmit = async (values) => {
    setLoading(true)
    const value = { ...values, nombre: values.nombre.toUpperCase(), predio: values.predio.toUpperCase() }
    await interfaces.configInterface
      .newLot(value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleClose)
    setLoading(false)
  }

  return (
    <div>
      <DialogComponent
        open={open}
        handleclose={handleClose}
        widthLarge="500px"
        title="Nuevo lote"
        disableChildHeight
        disableBackdropClick
        disableEscapeKeyDown
        children={<Form ref={formSubmitRef} onSubmit={onSubmit} />}
        actions={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <CancelConfirmButtons
              onClickCancel={handleClose}
              onClickSubmit={handleSubmit}
              disabledCancel={loading}
              disabledSubmit={loading}
              isLoading={loading}
            />
          </div>
        }
      />
    </div>
  )
}

export default New
