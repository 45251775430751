import React, { useState, useEffect } from "react"
import { interfaces } from "service/interfaces"
import BasicTable from "Components/UI/Atoms/BasicTable"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import Search from "Components/UI/Atoms/Search"
import { Grid, TablePagination } from "@material-ui/core"
import Filters from "./Filters"

const Report = ({ handleClose, open }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [formEdit, setFormEdit] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [ count, setCount ] = useState(0)
  
  const [filterEstado, setFilterEstado] = useState(null)
  const [filterTipo, setFilterTipo] = useState(null)
  const [filterActa, setFilterActa] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  
  async function datesApiCall(currentPage, value, origen, estado, acta) {
    const options = `?limit=${25}&offset=${currentPage}&search=${value}&origen=${origen}&estado=${estado}&acta=${acta}`
    await interfaces.compactionInterface
    .report(options)
    .then((res) => {
      setFormEdit(res.data)
      setCount(res.header.header.count)
    })
}
  useEffect(() => {
    const apiCall = async () => {
      setIsLoading(true)
      const options = `?limit=${25}&offset=${0}`
      try {
        const res = await interfaces.compactionInterface.report(options)
        setFormEdit(res.data)
        setCount(res.header.header.count)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setIsLoading(false)
      }
    }
    apiCall()
  }, [])

  async function handleChangeSearch(e) {
    const origen = filterTipo?.optionLabel || ''
    const value = e.target.value.toUpperCase()
    const estado = filterEstado?.optionLabel || 'PENDIENTE'
    const acta = filterActa?.optionLabel || ''
    setSearchValue(value)
    setCurrentPage(0)
    await datesApiCall(0, value, origen, estado, acta)
  }

  const handleChangePage = async (event, newPage) => {
    const value = searchValue.toUpperCase()
    const origen = filterTipo?.optionLabel || ''
    const estado = filterEstado?.optionLabel || 'PENDIENTE'
    const acta = filterActa?.optionLabel || ''
    setCurrentPage(newPage)
    await datesApiCall(newPage, value, origen, estado, acta)
  }

  const handleApplyFilter = () => {
    const value = searchValue.toUpperCase()
    const origen = filterTipo?.optionLabel || ''
    const estado = filterEstado?.optionLabel || 'PENDIENTE'
    const acta = filterActa?.optionLabel || ''
    datesApiCall(0, value, origen, estado, acta)
    setOpenDialog(false)
  }

  return (
    <DialogComponent
      paddingReduce={true}
      tipo="center"
      open={open}
      handleclose={handleClose}
      handleclosedisabled={isLoading}
      disableBackdropClick={isLoading}
      disableEscapeKeyDown={isLoading}
      title="Piezas y vehículos en compactación"
      widthLarge={"1000px"}
      customHeight={"1000px"}
    >
     <>
        <Grid container spacing={2}> 
          <Grid item xs={11}>
            <Search  
              placeholder="Buscar por número de dominio"
              onChange={handleChangeSearch}
              value={searchValue}
            />
          </Grid>
          <Grid item xs={1}>
            <Filters 
              selectedOption={filterTipo}
              setSelectedOption={setFilterTipo}
              handleApplyFilter={handleApplyFilter}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              filterEstado={filterEstado}
              setFilterEstado={setFilterEstado}
              filterActa={filterActa}
              setFilterActa={setFilterActa}
            />
          </Grid>
        </Grid>
        
        <BasicTable
          col={[
            { title: "Fecha", style: { width: "80px" } },
            { title: "Dominio", style: { width: "80px" } },
            { title: "Marca", style: { width: "80px" } },
            { title: "Modelo", style: { width: "80px" } },
            { title: "Producto", style: { width: "80px" } },
            { title: "Tipo compac.", style: { width: "80px" } },
            { title: "Estado", style: { width: "80px" } },
            { title: "ID acta", style: { width: "80px" } },
          ]}
          size={"small"}
          rows={formEdit.map((row) => {
            return {
              fecha: row.fecha,
              dominio: row.dominio,
              marca: row.marca,
              modelo: row.modelo,
              producto: row.pieza,
              tipo: row.tipo,
              estado: row.estado,
              id: row.compactacion_id
            }
          })}
        />
        <div style={{ display: "flex", justifyContent: "flex-end", position: "sticky", bottom: "0", backgroundColor: "white", zIndex: "10"}} >
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            rowsPerPage={25}
            page={currentPage}
            onPageChange={handleChangePage}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
          />       
        </div>   
      </>
    </DialogComponent>
  )
}

export default Report
