import * as yup from "yup"

export const validationNewLot = yup.object().shape({
    numero: yup
      .number()
      .typeError("Este campo es requerido")
      .required("Este campo es requerido")
      .min(1, "El número de lote debe ser mayor o igual a 1"),
    ubicacion_id: yup
      .number()
      .required("Este campo es obligatorio")
      .typeError("Seleccione una opción"),
    predio: yup
      .string()
      .typeError("Este campo es requerido")
      .required("Este campo es requerido"),
  })
  
  export const editLot = yup.object().shape({
    ancho: yup
      .number()
      .typeError("El valor debe ser mayor o igual a 0")
      .min(0, "El valor debe ser mayor o igual a 0"),
    largo: yup
      .number()
      .typeError("El valor debe ser mayor o igual a 0")
      .min(0, "El valor debe ser mayor o igual a 0"),
    capacidad: yup
      .number()
      .typeError("El valor debe ser mayor o igual a 0")
      .min(0, "El valor debe ser mayor o igual a 0"),
    predio: yup
      .string()
      .typeError("Este campo es requerido")
      .required("Este campo es requerido"),
  })

export const editRow = yup.object().shape({
    ancho: yup
        .number()
        .typeError("El valor debe ser mayor o igual a 0")
        .min(0, "El valor debe ser mayor o igual a 0"),
    largo: yup
        .number()
        .typeError("El valor debe ser mayor o igual a 0")
        .min(0, "El valor debe ser mayor o igual a 0"),
})

export const validationNewRow = yup.object().shape({
    numero: yup
        .number()
        .typeError("Este campo es requerido")
        .required("Este campo es requerido")
        .min(1, "El número de lote debe ser mayor o igual a 1"),
})
