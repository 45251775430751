import React, { useRef, useState, useCallback, useEffect } from "react"
import { useKeyPressEvent, useCopyToClipboard } from "react-use"
import { toPng } from "html-to-image"
import ReactImageGallery from "react-image-gallery"

import CircularProgress from "@material-ui/core/CircularProgress"
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Tooltip,
  Box,
  Collapse,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import Alert from "@material-ui/lab/Alert"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import ListAltIcon from "@material-ui/icons/ListAlt"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import GetAppIcon from "@material-ui/icons/GetApp"

import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import SetState from "Components/Hooks/SetState"
import CustomTable from "Components/UI/Organisms/Ecosoft/CustomTable"
import VehicleInfo from "Components/UI/Organisms/Ecosoft/Card/VehicleInfo"
import { titles2 } from "Helpers"
import { interfaces } from "service/interfaces"
import useStyles from "./styles"
import "./cssReactImage.css"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { engineForm } from "./Fields"
import ImageZoom from "Components/UI/Atoms/ZoomImage"

const CardEcosoft = (props) => {
  const { item, photoIndex } = props

  const classes = useStyles()
  const ref = useRef()

  const { state: list, setOpen: openList, setClose: closeList } = SetState()
  const { state: stock, setOpen: openStock, setClose: closeStock } = SetState()
  const {
    state: openEngine,
    setOpen: setOpenEngine,
    setClose: closeEngine,
  } = SetState()
  const [fullImg, setFullImg] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(null)

  async function openDialogList() {
    setLoading(true)
    await interfaces.ecoSoft
      .stock_list(item.id)
      .then((res) => openStock({ dataArray: res.data }))
    setLoading(false)
  }

  async function openEngineData(engine) {
    await interfaces.salesStock.getEngineData(engine).then((res) => {
      setOpenEngine({ dataObject: res.data })
    })
  }

  const refPng = useRef(null)
  const [loadCapture, setloadCapture] = useState(false)
  const createDismissableSnackbar = useCustomSnackbar()
  const onButtonClick = useCallback(() => {
    setloadCapture(true)
    toPng(refPng.current, {
      cacheBust: true,
      allowTaint: false,
      useCORS: true,
      backgroundColor: "white",
    }).then(async (dataUrl) => {
      const img = await fetch(dataUrl)
      const imgBlob = await img.blob()
      try {
        navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": imgBlob,
          }),
        ])
        createDismissableSnackbar("Imagen copiada al portapapeles!", {
          variant: "success",
        })
        setloadCapture(false)
      } catch (error) {}
    })
    // eslint-disable-next-line
  }, [refPng])

  function firstLetter(text) {
    return text.charAt(0) + text.substring(1).toLowerCase()
  }

  // eslint-disable-next-line
  const [_, copyToClipboard] = useCopyToClipboard()
  function copy(text) {
    copyToClipboard(text)
    createDismissableSnackbar("Copiado!", {
      variant: "success",
    })
  }

  function imgThumbs(image_url) {
    let position = image_url.lastIndexOf("/")
    let newUrl = [
      image_url.slice(0, position),
      "/thumbs",
      image_url.slice(position),
    ].join("")
    return newUrl
  }

  function downloadURL(dominio, nrodev) {
    window.location.assign(
      `https://ecoautoparts-prod-frontend-bucket.s3.amazonaws.com/vehiculos/${dominio}/documentacion/${nrodev}.pdf`
    )
  }

  useEffect(() => {
    if (openModal === true) {
      setTimeout(() => {
        ref.current.fullScreen()
        return ref.current.slideToIndex(photoIndex)
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  function closeModals(onClick) {
    onClick()
    setOpenModal(false)
  }

  useKeyPressEvent("Escape", () => setOpenModal(false))

  function onMouseLeave() {
    setTimeout(() => {
      setShowAlert(false)
    }, 100)
  }

  const datosMotor = (item) => {
    const motorInfo = []

    item.cilindrada && motorInfo.push(item.cilindrada)
    item.turbo && motorInfo.push("T")
    item.combustible &&
      motorInfo.push(item.combustible === "NAFTA" ? "Nafta" : "Diésel")
    item.valvulas && motorInfo.push(`${item.valvulas}v`)

    return item.cilindrada && item.turbo
      ? motorInfo.join(" ").replace(/-/, "")
      : motorInfo.join(" ")
  }

  return (
    <>
      <Card className={classes.root} ref={refPng} key={item.id}>
        <CardHeader
          style={{ padding: "8px 16px 8px 16px" }}
          avatar={
            item.marca_foto ? (
              <Avatar
                aria-label="recipe"
                className={classes.avatar}
                src={item.marca_foto}
              />
            ) : null
          }
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tooltip
                title={
                  <h2>
                    &nbsp;&nbsp;{item.marca} {item.modelo} {item.año}
                    &nbsp;&nbsp;
                  </h2>
                }
              >
                <Typography variant="body1" id="titulo">
                  <b id="titulo" style={{ color: "#2E2E2E" }}>
                    {!item.marca_foto ? item.marca + " " : ""}
                    <b
                      style={{
                        fontWeight: 500,
                        fontSize: 18,
                        color: "#2E2E2E",
                      }}
                    >
                      {item.modelo}
                    </b>{" "}
                    <b
                      style={{
                        fontWeight: 400,
                        fontSize: 16,
                        color: "#2E2E2E",
                      }}
                    >
                      {item.año}
                    </b>
                  </b>
                </Typography>
              </Tooltip>
              <Tooltip title={`Copiar V${item.nrodev}`}>
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {item.nrodev ? (
                    <div
                      style={{
                        display: "flex",
                        cursor: "copy",
                        width: "20%",
                        textAlign: "end",
                      }}
                      onClick={() => copy(item.nrodev)}
                    >
                      <b
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          color: "#2E2E2E",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        v{item.nrodev}
                      </b>
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
              </Tooltip>
            </div>
          }
          subheader={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div
                  style={{
                    fontSize: "small",
                    display: "flex",
                    flexWrap: "nowrap",
                    minHeight: "1em",
                    color: "#999999",
                    fontWeight: 400,
                  }}
                >
                  {item.version ? item.version + " " : " "} {item.años_version}
                </div>
                <Typography variant="body2" color="textPrimary" id="titulo">
                  <b id="titulo" style={{ color: "#2E2E2E", fontWeight: 500 }}>
                    {item.ubicacion}
                  </b>
                  &nbsp;
                </Typography>
              </div>
              <Typography
                variant="body2"
                color="textPrimary"
                style={{
                  width: "35%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "-5px",
                    textAlign: "right",
                  }}
                >
                  <b
                    id="ubicacion"
                    style={{ width: "100%", color: "#2E2E2E", fontWeight: 500 }}
                  >
                    {item.predio ? `P${item.predio} - ` : ""}
                    {item.lote ? `L${item.lote}` : ""}
                    {item.fila ? ` - F${item.fila}` : ""}
                  </b>
                </div>
              </Typography>
            </div>
          }
        />
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ position: "absolute", width: "100%" }}>
            {item.comentario_ecosoft && (
              <Collapse in={showAlert}>
                <Alert
                  icon={false}
                  variant="filled"
                  style={{
                    position: "relative",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    align="center"
                  >
                    <b style={{ color: "#fff" }}>{item.comentario_ecosoft}</b>
                  </Typography>
                </Alert>
              </Collapse>
            )}
          </div>
          <div className={classes.parent}>
            <img
              src={
                item.fotografias[photoIndex]
                  ? imgThumbs(item.fotografias[photoIndex])
                  : process.env.PUBLIC_URL + "/Images/noPhoto.jpg"
              }
              alt="foto"
              className={classes.photo}
              onClick={() => setOpenModal(true)}
              onMouseOver={() => setShowAlert(true)}
              onMouseLeave={() => onMouseLeave()}
            />
          </div>
        </div>

        <CardContent style={{ padding: "16px 16px 0px 16px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Tooltip title={`Copiar ${item.dominio}`}>
              <Typography variant="body2">
                <b
                  style={{ cursor: "copy", fontSize: 18, color: "#2E2E2E" }}
                  onClick={() => copy(item.dominio)}
                >
                  {item.dominio}
                </b>
              </Typography>
            </Tooltip>
            <Tooltip title={item.nrodev ? "Descargar baja" : ""}>
              <Typography
                id="ubicacion"
                variant="body1"
                style={{
                  textAlign: "right",
                  color:
                    item.tipo_baja === "04-D"
                      ? "#42814C"
                      : item.tipo_baja === "04-AM"
                      ? "#C65A02"
                      : "#C61A00",
                  cursor: item.nrodev ? "pointer" : "auto",
                }}
                onClick={() => downloadURL(item.dominio, item.nrodev)}
              >
                <b id="ubicacion">{item.tipo_baja}</b>
              </Typography>
            </Tooltip>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "80%",
                display: "flex",
              }}
            >
              <Typography
                variant="body2"
                component="p"
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: "40%",
                  cursor: "pointer",
                  transition: "cursor 0.3s",
                  color: "#2E2E2E",
                }}
                title={item.motor_codigo}
                onClick={() => item?.motor_id && openEngineData(item.motor_id)}
              >
                <b style={{ fontSize: 14, fontWeight: 500 }}>
                  {item.motor_codigo}
                </b>
              </Typography>
              <Typography
                variant="body2"
                color="textPrimary"
                component="p"
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  paddingLeft: "10px",
                  color: "#2E2E2E",
                }}
              >
                <b style={{ fontSize: 14, fontWeight: 500 }}>
                  {datosMotor(item)}
                </b>
              </Typography>
            </div>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{
                color:
                  item.estado_registral === "Finalizado"
                    ? "#519E5E"
                    : item.estado_registral === "No finalizado"
                    ? "#E21D00"
                    : item.estado_registral === "En trámite"
                    ? "#FF7503"
                    : "#0049A3",
                width: "100px",
                textAlign: "right",
              }}
            >
              <Box component="span" style={{ fontWeight: 550, fontSize: 14 }}>
                {item.estado_registral}
              </Box>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body2"
              color="textPrimary"
              component="p"
              style={{ width: "75%", color: "#2E2E2E" }}
            >
              <b style={{ fontSize: 14, fontWeight: 500 }}>
                {item.traccion ? `${firstLetter(item.traccion)} / ` : ""}
                {item.transmision ? `${firstLetter(item.transmision)} / ` : ""}
                {item.puertas ? `${item.puertas}P ` : ""}
                {item.kms ? `/ ${item.kms}` : ""}
                &nbsp;&nbsp;
              </b>
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              component="p"
              style={{ width: "25%", textAlign: "end", color: "red" }}
            >
              <b>{item.baja_extraviada === "SI" && "Baja extraviada"}</b>
            </Typography>
          </div>
        </CardContent>
        <CardActions disableSpacing style={{ padding: "0px 8px 8px 8px" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <Tooltip title="Información del vehículo">
              <IconButton onClick={() => openList()}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Listado de piezas">
              <IconButton onClick={() => openDialogList()}>
                {loading ? (
                  <CircularProgress
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                ) : (
                  <ListAltIcon />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title="Copiar">
              <IconButton
                className={classes.expand}
                onClick={onButtonClick}
                disabled={loadCapture}
              >
                {loadCapture ? (
                  <CircularProgress
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                ) : (
                  <FileCopyIcon />
                )}
              </IconButton>
            </Tooltip>
          </div>
        </CardActions>
      </Card>

      {list.suspense && (
        <VehicleInfo
          item={item}
          fullImg={fullImg}
          setFullImg={setFullImg}
          open={list.open}
          handleClose={closeList}
        />
      )}

      {openModal && (
        <div>
          <ReactImageGallery
            ref={ref}
            renderFullscreenButton={(onClick, disabled) => (
              <IconButton
                onClick={() => closeModals(onClick)}
                className={classes.classTest}
              >
                <FullscreenIcon style={{ width: "50px", height: "50px" }} />
              </IconButton>
            )}
            renderItem={({ original }) => (
              <ImageZoom
                src={original}
                zoom="300"
                height="85vh"
                isFullscreen={fullImg}
              />
            )}
            renderPlayPauseButton={() => (
              <IconButton
                className={classes.donwloadIcon}
                onClick={() =>
                  window.location.assign(
                    titles2(item.fotografias)[ref.current.getCurrentIndex()]
                      .original
                  )
                }
              >
                <GetAppIcon style={{ width: "50px", height: "50px" }} />
              </IconButton>
            )}
            items={titles2(item.fotografias)}
            onScreenChange={(e) => setFullImg({ fullScreen: e })}
            useBrowserFullscreen={false}
            showBullets={true}
            lazyLoad={true}
            showThumbnails={false}
            onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
          />
        </div>
      )}

      {stock.suspense && (
        <DialogComponent
          title={
            <div>
              {item.marca} {item.modelo_titulo}
            </div>
          }
          subtitle={
            <div>
              <b> Nº V: </b>
              {item.nrodev} - <b> Dominio:</b> {item.dominio}
            </div>
          }
          widthLarge="700px"
          open={stock.open}
          handleclose={closeStock}
          tipo="slide"
          children={
            <CustomTable
              rows={stock.dataArray}
              imagen={process.env.PUBLIC_URL + "/Images/noPhoto.jpg"}
              col={[
                {
                  title: "Código",
                  style: { paddingRight: 4, paddingLeft: 5 },
                },
                { title: "Pieza", style: { paddingRight: 4, paddingLeft: 5 } },
                { title: "Precio", style: { paddingRight: 4, paddingLeft: 5 } },
                { title: "Estado", style: { paddingRight: 4, paddingLeft: 5 } },
              ]}
            />
          }
        />
      )}
      {openEngine.suspense && (
        <DialogComponent
          title="Información del motor"
          disableChildHeight
          open={openEngine.open}
          handleclose={closeEngine}
          widthLarge="500px"
          children={
            <div style={{ marginBottom: "20px" }}>
              <InputsWithoutValidate
                fields={engineForm(openEngine.dataObject)}
                spacing={3}
              />
            </div>
          }
        />
      )}
    </>
  )
}

export default CardEcosoft
