import React, { useContext, useState } from "react"
import { Formik, Field } from "formik"
import { TextField } from "formik-material-ui"

import { Grid, Button, Hidden } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import InfoIcon from "@material-ui/icons/Info"
import { Tooltip } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextFieldMaterial from "@material-ui/core/TextField"

import useStyles from "../styles"
import { FormContext } from "Components/Hooks/ContextForm"
import { EditContext } from "Components/Hooks/ContextEdit"
import ControlOfShipments from "./Modals/ControlOfShipments"
import DialogWithLoader from "Components/UI/Molecules/Modals/WithLoader"
import { interfaces } from "service/interfaces"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { getUserId } from "service/helpers/localstorage"
import Loader from "Components/UI/Atoms/Loader"
import LowChange from "./Modals/LowChange"
import ReturnVehicles from "./Modals/ReturnVehicles"
import SetState from "Components/Hooks/SetState"

const Main = (props) => {
  const { setRenderTable, renderTable, renderModal, setRenderModal } = props

  const {
    state: dialogState,
    setOpen: setOpenDialog,
    setClose: setCloseDialog,
  } = SetState()
  const [wait /*setWait*/] = useState(false)

  function location() {
    if (
      formEdit.vehicle.predio === "" &&
      formEdit.vehicle.lote === "" &&
      formEdit.vehicle.fila === ""
    ) {
      return "Sin ubicación de predio, lote y fila"
    } else {
      return `${
        formEdit.vehicle.predio ? `Predio: ${formEdit.vehicle.predio}, ` : ""
      }${
        formEdit.vehicle.lote
          ? `Lote: ${formEdit.vehicle.lote}, `
          : " Sin Lote, "
      }${
        formEdit.vehicle.fila
          ? `Fila: ${formEdit.vehicle.fila}, `
          : " Sin Fila "
      }`
    }
  }

  const createDismissableSnackbar = useCustomSnackbar()

  async function onSubmitDNRPA() {
    await interfaces.vehicleInterface
      .dnrpaCall(formEdit.vehicle.id)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
      })
      .catch((err) => {
        createDismissableSnackbar(err.response.data.data, {
          variant: "error",
        })
      })
  }

  const afterSubmitDNRPA = () => {
    setRenderTable(!renderTable)
    setRenderModal(!renderModal)
  }

  const classes = useStyles()

  const [edit] = useContext(EditContext)

  const { formEdit, setFormEdit, formApi, setFormApi } = useContext(FormContext)

  const handleChange = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        [e.target.name]: parseInt(e.target.value),
      },
    })
  }

  const handleChangeText = (e) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        [e.target.name]: e.target.value,
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleChangeCompany = (id) => {
    setFormEdit({
      ...formEdit,
      vehicle: {
        ...formEdit.vehicle,
        cia_seguros_id: id,
      },
    })
    setFormApi({
      ...formApi,
      vehiculo: {
        ...formApi.vehiculo,
        cia_seguros_id: id,
      },
    })
  }

  return (
    <div autoComplete="off" className={classes.overflowDesactivo}>
      <Formik>
        <Grid
          container
          spacing={3}
          justify="center"
          alignItems="center"
          className={classes.paddingGrids}
        >
          <Grid item sm={3} xs={12}>
            <Field
              component={TextField}
              fullWidth
              disabled={
                edit && [1, 2].indexOf(parseInt(getUserId())) > -1
                  ? false
                  : true
              }
              variant="outlined"
              id="dominio"
              name="dominio"
              label="Dominio"
              value={formEdit.vehicle.dominio}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeText}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Field
              component={TextField}
              disabled
              fullWidth
              name="nrodev"
              variant="outlined"
              id="numeroV"
              label="N° de V"
              value={formEdit.vehicle.nrodev}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled
              fullWidth
              name="Tipo_de_vehiculo"
              variant="outlined"
              label="Tipo de vehículo"
              value={
                formEdit.vehicle.vehiculo_info.version_full.vehiculo_tipo
                  ?.nombre
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              getOptionLabel={(types) => types.nombre}
              disabled={
                edit && [1, 2].indexOf(parseInt(getUserId())) > -1
                  ? false
                  : true
              }
              noOptionsText={"No se encontraron resultados"}
              options={formEdit.selectInputs.compañias}
              defaultValue={formEdit.selectInputs.compañias.find(
                (v) => v.id === formEdit.vehicle.cia_seguros_id
              )}
              onChange={(event, value) => {
                handleChangeCompany(value.id)
              }}
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  fullWidth
                  label="Compañía de seguros"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled={edit ? false : true}
              fullWidth
              variant="outlined"
              onChange={handleChange}
              id="siniestro"
              name="nro_siniestro"
              label="Siniestro"
              value={formEdit.vehicle.nro_siniestro}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              component={TextField}
              disabled
              variant="outlined"
              name="descripcion"
              value={formEdit.vehicle.tipo_baja.descripcion}
              label="Tipo de baja"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <Field
              fullWidth
              component={TextField}
              disabled
              value={formEdit.vehicle.pertenencia === 1 ? "ECO" : "NH"}
              name="hola"
              variant="outlined"
              label="Pertenece a"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Field
              type="number"
              component={TextField}
              fullWidth
              disabled={edit ? false : true}
              onChange={handleChange}
              name="nro_form04"
              value={formEdit.vehicle.nro_form04}
              label="Formulario 04-D / Chasis"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={3} xs={6}>
            <Field
              type="number"
              component={TextField}
              fullWidth
              disabled={
                edit && formEdit.vehicle.tipo_baja.descripcion === "04-AM"
                  ? false
                  : true
              }
              onChange={handleChange}
              name="nro_form04motor"
              value={formEdit.vehicle.nro_form04motor}
              label="Formulario 04-AM Motor"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              type="number"
              component={TextField}
              fullWidth
              disabled={
                edit ? formEdit.vehicle.tipo_baja.descripcion !== "04-D" : true
              }
              onChange={handleChange}
              name="nro_cert_baja"
              value={formEdit.vehicle.nro_cert_baja}
              label="Certificado baja"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled
              variant="outlined"
              fullWidth
              name="ubicacion"
              value={formEdit.vehicle.ubicacion.nombre}
              label="Ubicación"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={location()}>
                      <InfoIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled
              variant="outlined"
              fullWidth
              name="estado"
              value={formEdit.vehicle.estado.descripcion}
              label="Estado de trámite"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Field
              component={TextField}
              disabled
              variant="outlined"
              fullWidth
              name="estado_registral"
              value={formEdit.vehicle.estado_registral}
              label="Estado registral"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid container spacing={2} sm={12} xs={12}>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                xs={12}
              >
                <ReturnVehicles
                  vehicle_id={formEdit.vehicle.id}
                  afterSubmitDNRPA={afterSubmitDNRPA}
                  estado_id={formEdit.vehicle.estado_id}
                />
                <LowChange
                  vehicle_id={formEdit.vehicle.id}
                  tipo_baja_id={formEdit.vehicle.tipo_baja_id}
                  estado_id={formEdit.vehicle.estado_id}
                  afterSubmitDNRPA={afterSubmitDNRPA}
                />
                <ControlOfShipments vehicle_id={formEdit.vehicle.id} />
                <Button
                  style={{
                    margin: "5px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={setOpenDialog}
                  disabled={
                    edit
                      ? formEdit.vehicle.tipo_baja.descripcion !== "04-D"
                      : true
                  }
                >
                  Traer datos DNRPA
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <DialogWithLoader
            open={dialogState.open}
            close={setCloseDialog}
            title={"¿Desea traer los datos del servidor de DNRPA?"}
            subtitle={"Esta acción puede demorar algunos segundos"}
            awaitMessage={"¡Aguarde! Conectando al servicio DNRPA"}
            onSubmit={onSubmitDNRPA}
            afterSubmit={afterSubmitDNRPA}
          />
        </Grid>
      </Formik>
      {wait ? <Loader /> : null}
    </div>
  )
}

export default Main
