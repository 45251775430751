import { React, forwardRef } from "react"
import { TextField } from "formik-material-ui"
import { Formik, Field } from "formik"

import { Grid } from "@material-ui/core"
import TextFieldMaterial from "@material-ui/core/TextField"
import { validationNewLot } from "Components/Validation/Settings/Various/LotsRow"
import Autocomplete from "@material-ui/lab/Autocomplete"

const Form = forwardRef((props, ref) => {
  
  const { onSubmit } = props
  
  const options = [
    {
      id: 1,
      optionLabel: "ECO Río Cuarto",
    },
    {
      id: 2,
      optionLabel: "ECO Spernanzoni",
    },
    {
      id: 3,
      optionLabel: "ECO Ruta 7",
    },
    {
      id: 4,
      optionLabel: "ECO 25 de Mayo",
    },
    {
      id: 5,
      optionLabel: "OEM Ruta 7",
    },
    {
      id: 7,
      optionLabel: "En tránsito",
    },
  ]

  const object = [
    {
        id: 0,
        optionLabel: "NO",
      },
      {
        id: 1,
        optionLabel: "SI",
      },
  ]

  return (
    <>
      <Formik
        initialValues={{
          numero: null,
          nombre: "",
          ubicacion_id: null,
          predio: "",
          capacidad: null,
          ancho: null,
          largo: null,
          es_compactacion: 0
        }}
        validationSchema={validationNewLot}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ submitForm, isSubmitting, setFieldValue, errors, touched }) => (
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Nº de lote"
                  name="numero"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Nombre"
                  name="nombre"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Autocomplete
                  getOptionLabel={(option) => option.optionLabel}
                  options={options}
                  onChange={(event, value) => {
                    setFieldValue("ubicacion_id", value.id)
                  }}
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      fullWidth
                      label="Ubicación"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      
                      error={touched.ubicacion_id && Boolean(errors.ubicacion_id)}
                      helperText={touched.ubicacion_id && errors.ubicacion_id}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Predio"
                  name="predio"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Capacidad"
                  name="capacidad"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Ancho"
                  name="ancho"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={4} xs={12}>
              <div style={{ height: "70px" }}>
                <Field
                  component={TextField}
                  fullWidth
                  label="Largo"
                  name="largo"
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 50,
                    min: 0
                  }}
                />
              </div>
            </Grid>

            <Grid item sm={12} xs={12}>
              <div style={{ height: "70px" }}>
                <Autocomplete
                  getOptionLabel={(option) => option.optionLabel}
                  options={object}
                   defaultValue={object.find((e) => {
                     return e.id === 0
                   })}
                  onChange={(event, value) => {
                    setFieldValue("es_compactacion", value.id)
                  }}
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      fullWidth
                      label="Es lote compactación?"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </div>
            </Grid>

            <button
              type="submit"
              onClick={submitForm}
              disabled={isSubmitting}
              ref={ref}
              style={{ display: "none" }}
            />
          </Grid>
        )}
      </Formik>
    </>
  )
})
export default Form
