import React, { useEffect, useRef, useState } from "react"
import ReactImageGallery from "react-image-gallery"

import { Grid, Divider } from "@material-ui/core"

import { UBICACIONES_MANUAL } from "Constants"
import InputsWithoutValidate from "Components/UI/Molecules/InputsWithoutValidate"
import { titles } from "Helpers"

const Content = (props) => {
  const { data, handleChange } = props
  const ref = useRef()
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600)
    }
    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const LOTES = data.lots.map((e) => {
    return {
      id: e.id,
      numero: e.numero,
      ubicacion_id: e.ubicacion_id,
      es_compactacion: e.es_compactacion,
      predio: e.predio,
    }
  })
  const FILA = data.rows.map((e) => {
    return {
      id: e.id,
      numero: e.numero,
      lote_id: e.lote_id,
    }
  })
  const optionsLocations = UBICACIONES_MANUAL.filter((ubicacion) =>
    LOTES.some((lote) => lote.ubicacion_id === ubicacion.id)
  )

  const idLote = LOTES.find((e) => e.numero === data.lote && e.ubicacion_id === data.ubicacion_id)?.id
 
  const fields = [
    {
      class: "textfield",
      disabled: true,
      name: "dominio",
      label: "Dominio",
      value: data.dominio,
      sm: 6,
      xs: 6,
    },
    {
      class: "textfield",
      disabled: true,
      name: "nrodev",
      label: "Nº V",
      value: data.nrodev,
      sm: 6,
      xs: 6,
    },
    {
      class: "textfield",
      disabled: true,
      name: "marca",
      label: "Marca",
      value: data.marca,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      disabled: true,
      name: "modelo",
      label: "Modelo",
      value: data.modelo,
      sm: 12,
      xs: 12,
    },
    {
      class: "textfield",
      disabled: true,
      name: "color",
      label: "Color",
      value: data.color,
      sm: 6,
      xs: 6,
    },
    {
      class: "textfield",
      disabled: true,
      name: "año",
      label: "Año",
      value: data.año,
      sm: 6,
      xs: 6,
    },
    {
      class: "textfield",
      disabled: true,
      name: "estado",
      label: "Estado",
      value: data.estado,
      sm: 12,
      xs: 12,
    },
    {
      class: "custom",
      xs: "12",
      sm: "12",
      component: <Divider style={{ padding: 0 }} />,
    },
    {
      class: "autocomplete",
      name: "ubicacion_id",
      label: "Ubicación",
      value: data.ubicacion_id,
      options: optionsLocations,
      optionValue: "id",
      optionLabel: `options["nombre"]`,
      sm: 12,
      xs: 12,
      onChange: (e) => {
        handleChange(
          "ubicacion_id",
          e.id,
          "predio",
          null,
          "lote",
          null,
          "fila",
          null
        )
      },
    },
    {
      class: "autocomplete",
      name: "predio",
      label: "Predio",
      value: data.predio ? data.predio : null,
      options: Array.from(
        new Set(
          data.sites
            .filter(
              (predio) =>
                predio.ubicacion_id === data.ubicacion_id &&
                predio.predio !== null
            )
            .map((predio) => predio.predio)
        )
      )
        .sort((a, b) => {
          const aString = a.toString()
          const bString = b.toString()
    
          if (isNaN(aString) && isNaN(bString)) {
            return aString.localeCompare(bString)
          }
          if (!isNaN(aString) && isNaN(bString)) {
            return -1
          }
          if (isNaN(aString) && !isNaN(bString)) {
            return 1
          }
          return parseFloat(aString) - parseFloat(bString)
        })
        .map((uniquePredio) => ({ predio: uniquePredio })),
      optionValue: "predio",
      optionLabel: isMobile
        ? `options["predio"]`
        : `options["predio"] ? options["predio"] : "null"`,
      sm: 4,
      xs: 6,
      onChange: (e) => {
        handleChange("predio", e.predio, "lote", null, "fila", null)
      },
    },
    {
      class: "autocomplete",
      name: "lote",
      label: "Lote",
      value: data.lote,
      options: LOTES.filter(
        (lote) =>
          lote.ubicacion_id === data.ubicacion_id && lote.predio === data.predio
      ).sort((a, b) => a.numero - b.numero),
      optionValue: "numero",
      optionLabel: `options["numero"].toString()`,
      sm: 4,
      xs: 6,
      onChange: (e) => {
        handleChange("lote", e.numero, "fila", null);
      },
      disabled: data.predio === null,
    },
    {
      class: "autocomplete",
      name: "fila",
      label: "Fila",
      value: data.fila,
      options: FILA.filter((fila) => fila.lote_id === idLote).sort(
        (a, b) => a.numero - b.numero 
      ),
      optionValue: "numero",
      optionLabel: `options["numero"].toString()`,
      sm: 4,
      xs: 6,
      onChange: (e) => {
        handleChange("fila", e.numero);
      },
      disabled: data.lote === null,
    },
    
  ]

  return (
    <div style={{ marginBottom: "10px" }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item sm={4} xs={12}>
          <InputsWithoutValidate fields={fields} spacing={2} />
        </Grid>
        <Grid item sm={8} xs={12}>
          <ReactImageGallery
            ref={ref}
            items={titles(data.foto)}
            useBrowserFullscreen={false}
            showBullets={true}
            showPlayButton={false}
            lazyLoad={true}
            showThumbnails={false}
            onErrorImageURL={process.env.PUBLIC_URL + "/Images/unavailable.jpg"}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default Content
