export function columnTheme() {
    return {
      "&:nth-child(1)": {
        paddingLeft: "10px",
        width: "100px",
      },
      "&:nth-child(2)": {
        width: "220px",
      },
      "&:nth-child(3)": {
        width: "120px",
      },
      "&:nth-child(4)": {
        width: "120px",
      },
      "&:nth-child(5)": {
        width: "280px",
      },
      "&:nth-child(6)": {
        width: "150px",
      },
      "&:nth-child(7)": {
        width: "150px",
      },
      "&:nth-child(8)": {
        width: "140px",
      },
      "&:nth-child(9)": {
        width: "10px",
      },
      "&:nth-child(10)": {
        width: "50px",
      },
    }
  }

export const buttonsStyle = {
  display: "flex",
  justifyContent: "flex-end",
  position: "sticky", // Mantiene los botones visibles al hacer scroll
  bottom: 0, // Se pega al final del contenedor
  backgroundColor: "white", // Para que no se mezcle con el contenido
  zIndex: 10, // Asegura que esté por encima del contenido
}