import { React, useRef, useState } from "react"

import Form from "./Form"
import DialogComponent from "Components/UI/Molecules/Modals/DialogComponent"
import useCustomSnackbar from "Components/UI/Atoms/SnackBar"
import { interfaces } from "service/interfaces"

import CancelConfirmButtons from "Components/UI/Molecules/CancelConfirmButtons"

const Edit = (props) => {
  const { open, handleclose, data, lotId, changeRender } = props
  const formSubmitRef = useRef(null)
  const createDismissableSnackbar = useCustomSnackbar()
  
  const handleSubmit = () => {
    formSubmitRef.current.click()
  }
  const [loading, setLoading] = useState(false)
 
  const onSubmit = async (values) => {
    setLoading(true)
    const value = {
      numero: values.numero,
      nombre: values.nombre.toUpperCase(),
      capacidad: values.capacidad,
      ancho: values.ancho,
      largo: values.largo,
      es_compactacion: values.es_compactacion,
      predio: values.predio.toUpperCase()
    }
    await interfaces.configInterface
      .updateLot(lotId, value)
      .then((res) => {
        createDismissableSnackbar(res.data, {
          variant: "success",
        })
        changeRender()
      })
      .catch((error) =>
        createDismissableSnackbar(error.response.data.data, {
          variant: "error",
        })
      )
      .finally(handleclose)
    setLoading(false)
  }

  return (
    <DialogComponent
      open={open}
      handleclose={handleclose}
      widthLarge="500px"
      title="Editar lote"
      children={
        <Form
          ref={formSubmitRef}
          onSubmit={onSubmit}
          data={data}
        />
      }
      disableChildHeight
      disableBackdropClick
      disableEscapeKeyDown
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CancelConfirmButtons
            onClickCancel={handleclose}
            onClickSubmit={handleSubmit}
            disabledCancel={loading}
            disabledSubmit={loading}
            isLoading={loading}
          />
        </div>
      }
    />
  )
}

export default Edit
